.scroll-box {
    display: block;
    height: 129px;
    width: 100%;
    overflow:auto;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fit-checkbox {
    display: block;
    width: 100%;
    overflow:auto;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container {
    /*スクロールバー全体*/
    ::-webkit-scrollbar {
        width: 10px;
    }

    /*スクロールバーの軌道*/
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
    }

    /*スクロールバーの動く部分*/
    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 50, .5);
        border-radius: 10px;
        box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
    }
}

.min-w-30px {
    min-width: 30px !important;
}

.min-w-100px {
    min-width: 100px !important;
}

.max-w-270px {
    max-width: 270px !important;
}

.w-70px {
    width: 70px !important;
}

.w-120px {
    width: 120px !important;
}

.w-150px {
    width: 150px !important;
}

.w-200px {
    width: 200px !important;
}

.w-270px {
    width: 270px !important;
}
