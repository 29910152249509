:root {
    --editor-width: 362px;
}

#toolbar-container {
    width: var(--editor-width);

    svg {
        vertical-align: baseline;
    }

    .ql-size {
        width: 65px;

        .ql-picker-options {
            max-height: 400px;
            overflow-y: auto;
        }
    }
}

.js-quill-editor {
    width: var(--editor-width);
    height: calc(var(--editor-width) * 16 / 9);
    font-size: 10pt;
    font-family: sans-serif;

    .ql-editor {
        padding: 4px;
        overflow-y: scroll;
    }
}

.quill-contents {
    width: var(--editor-width);
    height: fit-content;
    padding: unset;
}
