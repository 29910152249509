.relative {
    position: relative;
}

.padding-icon {
    padding-right: 36px;
}

.mask-icon {
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.valid-error-message {
    font-size: 80%;
    color: #e3342f !important;
}
