.dataTables_wrapper {
    .row {
        &:first-of-type {
            display: flex;
            flex-flow: row-reverse;

            > div {
                flex-shrink: 1;

                .dataTables_length {
                    text-align: right;
                }

                .dataTables_filter {
                    text-align: left;

                    label {
                        width: 100%;

                        input[type="search"] {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}
