.is-hide{
    display:none;
}
.loading{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:rgba(0,0,0,.5);
}
.loading::before{
    content:"";
    display:block;
    position:fixed;
    left:50%;
    top:50%;
    width:50px;
    height:50px;
    border-radius:5px;
    margin-top:-15px;
    margin-left:-15px;
    background:white;
}
.loading::after{
    content:"";
    display:block;
    position:fixed;
    left:50%;
    top:50%;
    width:32px;
    height:32px;
    border-radius:20px;
    margin-top:-6px;
    margin-left:-6px;
    border:4px solid #60ABB9;
    border-right:4px solid white;
    animation: rotate 1s infinite linear;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
